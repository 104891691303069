










import Vue from "vue";
import BasicButton from "./BasicButton.vue";

export default Vue.extend({
  components: { BasicButton },
  props: {
    buttonStyle: { type: String, default: "" },
    typeSubmit: { type: Boolean, default: true },
  },
});
