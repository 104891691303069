











/// <reference path="../../vue-snack-vue-property.d.ts" />

import Vue from "vue";
import { refreshToUpdate } from "@/showMessageOnNextPageReload";

export default Vue.extend({
  data() {
    return { spin: false };
  },
  methods: {
    checkForUpdates(): void {
      document.addEventListener("needs-refresh", refreshToUpdate);
      document.dispatchEvent(new Event("check-for-updates"));
      this.spin = true;
      setTimeout(() => {
        document.removeEventListener("needs-refresh", refreshToUpdate);
        this.spin = false;
        if (!(window as any).needsRefresh) {
          this.$snack.show({
            text: "No updates found",
            button: "",
          });
        }
      }, 4000);
    },
  },
});
