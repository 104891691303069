











import Vue from "vue";
import { showMessageOnNextPageReload } from "@/showMessageOnNextPageReload";

export default Vue.extend({
  methods: {
    reloadPage() {
      showMessageOnNextPageReload("Refreshed app");
      window.location.reload();
    },
  },
});
