










import Vue from "vue";

export default Vue.extend({
  computed: {
    darkTheme: {
      get() {
        return this.$store.state.isDarkMode;
      },
      set(newVal) {
        this.$store.commit("setDarkTheme", newVal);
      },
    },
  },
});
