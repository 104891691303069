



































import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import { Room } from "room-finder";
import CustomButton from "@/components/buttons/CustomButton.vue";
import { walnutNonAccessible } from "@/walnut";
import store from "@/store";
import { COVID_ONE_WAY_HALLWAY_AND_STAIRS } from "@/walnut/shared";

function fullNameOf(roomName: string) {
  const [hallwayInd, ind] = walnutNonAccessible.getHallwayIndexAndIndex(
    roomName
  )!;
  return (walnutNonAccessible.hallways[hallwayInd].partList[
    ind
  ] as Room<string>).fullName;
}

export default Vue.extend({
  components: {
    CustomButton,
  },
  props: {
    fromRoom: { type: String, default: "" },
    toRoom: { type: String, default: "" },
  },
  data() {
    return {};
  },
  computed: {
    isValid() {
      return (
        walnutNonAccessible.getHallwayIndexAndIndex(this.fromRoom) != null &&
        walnutNonAccessible.getHallwayIndexAndIndex(this.toRoom) != null
      );
    },
    directionsString(): string {
      if (this.isValid) {
        // Both have valid names, so put the directions in the HTML
        return store.getters.walnut
          .getDirections(this.fromRoom, this.toRoom)!
          .trim();
      }
      return "Sorry, I couldn't find one of those rooms.";
    },
    directionsLines(): string[] {
      return this.directionsString.split("\n");
    },
    canPrint() {
      return window.print;
    },
  },
  methods: {
    needsPrecaution(directions: string): boolean {
      if (!COVID_ONE_WAY_HALLWAY_AND_STAIRS) return false;
      const lowercase = directions.toLowerCase();
      return (
        lowercase.includes("turn left out of") ||
        lowercase.includes("turn left into")
      );
    },
    back() {
      if (this.$route.query.isFromSchedule === "true") {
        this.$router.push("/myschedule");
      } else {
        this.$router.push("/");
      }
    },
    print() {
      window.print();
    },
  },
  metaInfo() {
    const { fromRoom, toRoom, isValid } = this as any;
    return isValid
      ? {
          title: `${fromRoom} to ${toRoom}`,
          meta: [
            {
              vmid: "description",
              name: "description",
              content: `Directions from ${fullNameOf(fromRoom)} to ${fullNameOf(
                toRoom
              )} in Walnut Hills High School.`,
            },
          ],
        }
      : {};
  },
});
