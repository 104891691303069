








import Vue from "vue";
import { walnutNonAccessible } from "@/walnut";

const { roomsList } = walnutNonAccessible;

export default Vue.extend({
  computed: {
    roomsList: () => roomsList,
  },
});
