










import Vue from "vue";
import CustomButton from "@/components/buttons/CustomButton.vue";

export default Vue.extend({
  metaInfo: { title: "Not Found" },
  components: { CustomButton },
});
