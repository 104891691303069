





















import Vue from "vue";

export default Vue.extend({
  computed: {
    showiOSDownloadSuggestion() {
      // If it's an iOS device and we're not already in the PWA, unhide the #iosDownloadSuggestion
      const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isInstalledPWA =
        "matchMedia" in window &&
        window.matchMedia("(display-mode: standalone)").matches;
      return iOS && !isInstalledPWA;
    },

    // non-Safari iOS browsers can't install PWAs
    isSafari() {
      // Given that we're on iOS:
      // non-Safari browsers seem to have, for example, "FxiOS/" or "CriOS/" or "OPiOS/" instead of "Version/"
      return navigator.userAgent.includes("Version/");
    },
  },
});
