













































import Vue from "vue";

import TheRoomsDataList from "@/components/TheRoomsDataList.vue";
import router from "@/router/index";

export default Vue.extend({
  router,
  components: { TheRoomsDataList },
  data() {
    return {
      transitionName: "fade",
    };
  },
  watch: {
    $route(to, from) {
      if (
        "media" in window &&
        window.matchMedia("(prefers-reduced-motion: reduce)").matches
      ) {
        this.transitionName = "fade";
      } else if (from.path === "/" && to.path === "/directions") {
        this.transitionName = "slide-left";
      } else if (from.path === "/directions" && to.path === "/") {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "fade";
      }
    },
  },
  mounted() {
    const currentRouteNavItem = document.querySelector(
      "header li.router-link-active a"
    );
    if (currentRouteNavItem != null) {
      currentRouteNavItem.scrollIntoView({ inline: "center" });
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Walnut Hills Directions",
    // all titles will be injected into this template
    titleTemplate: `Walnut.Direct - %s`,

    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Straightforward directions between rooms in Walnut Hills High School. Created by the Walnut Hills Programming Club.",
      },
    ],
  },
});
