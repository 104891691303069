































































































import Vue from "vue";
import { SlickList, SlickItem } from "vue-slicksort";
import RoomInput from "@/components/RoomInput.vue";
import CustomButton from "@/components/buttons/CustomButton.vue";
import MinusButton from "@/components/buttons/MinusButton.vue";
import PlusButton from "@/components/buttons/PlusButton.vue";
import { walnutNonAccessible } from "@/walnut";

interface Room {
  value: string;
  originalIndex: number;
}

function getDefaultRooms() {
  const ret: Room[] = [];
  for (let i = 0; i < 8; i += 1) {
    ret.push({ value: "", originalIndex: i });
  }
  return ret;
}

function getStoredRooms() {
  return localStorage.getItem("myschedule");
}

function roomsListsEqual(a: Room[], b: Room[]) {
  const aValues = a.map(({ value }) => value).filter((v) => v !== "");
  const bValues = b.map(({ value }) => value).filter((v) => v !== "");
  if (aValues.length !== bValues.length) {
    return false;
  }
  for (let i = 0; i < aValues.length; i += 1) {
    if (aValues[i] !== bValues[i]) return false;
  }
  return true;
}

export default Vue.extend({
  components: {
    RoomInput,
    CustomButton,
    SlickList: SlickList as any,
    SlickItem: SlickItem as any,
    MinusButton,
    PlusButton,
  },
  beforeRouteLeave(to, from, next) {
    const stored = getStoredRooms();
    if (
      (stored == null && roomsListsEqual(this.rooms, getDefaultRooms())) ||
      (stored != null && roomsListsEqual(this.rooms, JSON.parse(stored).rooms))
    ) {
      next();
    } else {
      // eslint-disable-next-line no-alert
      const answer = window.confirm(
        "Are you sure you want to leave? Your changes to your schedule will NOT be saved."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
  data() {
    const stored = getStoredRooms();
    let rooms: Room[];
    if (stored != null) {
      rooms = JSON.parse(stored).rooms;
    } else {
      rooms = getDefaultRooms();
    }
    return { rooms };
  },
  methods: {
    newRoom() {
      this.rooms.push({ value: "", originalIndex: this.rooms.length });
    },
    save() {
      Array.from(document.getElementsByClassName("my-input")).forEach(
        (theInput) => {
          const inp = theInput as HTMLInputElement;
          if (
            inp.value === "" ||
            walnutNonAccessible.isValidRoomName(inp.value)
          ) {
            inp.setCustomValidity("");
          } else {
            inp.setCustomValidity(
              `I can't find a room with the name ${inp.value}`
            );
          }
        }
      );
      const form = document.getElementById("scheduleForm") as HTMLFormElement;
      if ("reportValidity" in form /* browser support */) form.reportValidity();
      if (
        this.rooms.every(
          ({ value }) =>
            value.trim() === "" || walnutNonAccessible.isValidRoomName(value)
        )
      ) {
        /* */
        localStorage.setItem(
          "myschedule",
          JSON.stringify({ rooms: this.rooms })
        );
        this.$router.push("/myschedule");
      }
    },
    cancel() {
      this.$router.push("/myschedule");
    },
    removeIndex(index: number) {
      this.rooms.splice(index, 1);
    },
  },
  metaInfo: {
    title: "My Schedule - Edit",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Input your schedule into Walnut.Direct so we can give you directions between your classes in Walnut Hills High School.",
      },
    ],
  },
});
