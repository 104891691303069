




























































































































import Vue from "vue";

export default Vue.extend({
  metaInfo: { title: "About" },
});
