



























// eslint-disable-next-line no-unused-vars
import Vue, { PropType } from "vue";
import CustomButton from "@/components/buttons/CustomButton.vue";

interface StoredRoom {
  value: string;
  originalIndex: number;
}

interface StoredRoomWithActualIndex extends StoredRoom {
  actualIndex: number;
}

function range(len: number) {
  return [...Array(len).keys()];
}

export default Vue.extend({
  components: { CustomButton },
  props: {
    allRooms: { type: Array as PropType<StoredRoom[]>, required: true },
    order: {
      type: Array as PropType<number[] | null>,
      default: null,
    },
  },
  computed: {
    orderWithDefault() {
      return this.order ?? range(this.allRooms.length);
    },
    rooms(): StoredRoomWithActualIndex[] {
      return this.orderWithDefault.map((ind) => ({
        ...(this.allRooms[ind] ?? { value: "", originalIndex: 0 }),
        actualIndex: ind,
      }));
    },
  },
  methods: {
    go(index: number) {
      this.$emit("go", {
        fromRoom: this.rooms[index].value,
        toRoom: this.rooms[index + 1].value,
      });
    },
  },
});
