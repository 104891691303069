
























import Vue from "vue";

export default Vue.extend({
  metaInfo: {
    title: "Feedback",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Report problems with Walnut.Direct or ask us a question.",
      },
    ],
  },
});
